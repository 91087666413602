<template>
  <div>
    <van-cell :title="PatientName" is-link to="/CcardL" value="切换用户">
      <!-- 使用 title 插槽来自定义标题 -->
      <van-icon
        slot="right-icon"
        class="iconfont"
        class-prefix="icon"
        name="qiehuan"
        color="#57c4b7"
      />
    </van-cell>
    <!-- <van-cell title="自助开单记录" @click="gotoList" /> -->
    <van-cell
      title="温馨提示：核酸自助开单只支持开立当天核酸检测"
      style="color: red; font-size: 12px"
    />
    <div v-if="!isshow">
      <van-list
        v-for="(item, index) in groupList"
        :key="index"
        v-model="loading"
        :finished="finished"
        style="margin-top: 12px"
      >
        <!-- <van-cell title="待缴费订单">
        <template slot="default">
          总金额：
          <span style="color: #57c4b7">¥{{ FeeSum }}</span>
        </template>
      </van-cell> -->
        <van-cell-group>
          <van-cell title="新冠核酸检测开单确认表：">
            <template slot="label">
              <div style="font-size: 12px; line-height: 25px; padding: 0 10px">
                <p>科 室：{{ listData.deptName }}</p>
                <p>类 型：{{ item.groupName }}</p>
                <p>时 段：{{ listData.timeSpan }}</p>
                <p>
                  状 态：<span :style="{ color: isMore.color }">{{
                    isMore.text
                  }}</span>
                </p>
                <p>
                  余 号 量：<span :style="{ color: rmngColor }">{{
                    listData.rmngNum
                  }}</span>
                </p>
                <p>
                  总费用：<span style="color: red"
                    >{{ item.groupPrice }}元</span
                  >
                </p>
                <p style="text-align: center; margin-top: 10px">
                  <van-radio-group v-model="radio">
                    <van-cell-group style="margin-top:13px;">
                      <van-cell
                        title="微信支付"
                        style="font-size:16px;height:46px;line-height:30px"
                        clickable
                        @click="radio = '1'"
                      >
                        <template #icon>
                          <span style="margin-right:15px;"
                            ><img
                              :src="weixicon"
                              alt=""
                              style="margin-left:5px;width:40px;height:30px;"
                          /></span>
                        </template>
                        <template #right-icon>
                          <van-radio checked-color="#57c4b7" name="1" />
                        </template>
                      </van-cell>
                      <van-cell
                        title="医保支付"
                        style="font-size:16px;height:40px;line-height:30px"
                        clickable
                        @click="radio = '2'"
                      >
                        <template #icon>
                          <span style="margin-right:10px;"
                            ><img
                              :src="ybicon"
                              alt=""
                              style="width:50px;height:15px;"
                          /></span>
                        </template>
                        <template #right-icon>
                          <van-radio checked-color="#57c4b7" name="2" />
                        </template>
                      </van-cell>
                    </van-cell-group>
                  </van-radio-group>

                  <van-button
                    v-if="radio == 2"
                    type="primary"
                    style=" position: fixed;bottom:3%;left:5%;right:5%; width:90%; height: 50px;"
                    color="#57c4b7"
                    round
                    size="large"
                    @click="gotoPay(lists)"
                  >
                    前往医保支付
                  </van-button>
                  <van-button
                    v-else-if="radio == 1"
                    type="primary"
                    style=" position: fixed;bottom:3%;left:5%;right:5%; width:90%; height: 50px;"
                    color="#57c4b7"
                    round
                    size="large"
                    @click="
                      onclickBtn(item.groupPrice, item.groupName, item.groupID)
                    "
                  >
                    前往支付
                  </van-button>

                  <!-- <van-button
                    :disabled="btnisClick"
                    type="primary"
                    @click="
                      onclickBtn(item.groupPrice, item.groupName, item.groupID)
                    "
                    >确认开单</van-button
                  > -->
                </p>
              </div>
            </template>
          </van-cell>
      </van-cell-group>
      </van-list>
    </div>
    <div
      v-if="isshow"
      style="margin:30px auto;text-align:center;font-size:15px;width:80%;height:120px;background:#fff;line-height:100px;border-radius:10px;"
    >
      {{ errmgg ? errmgg : "当前未获取到排班信息!" }}
    </div>

    <div
      style="
        font-size: 12px;
        color: #d3d3d3;
        margin: 1% 40%;
        width: auto;
        height: 40px;
      "
    >
      <span>&copy;拓实银医</span>
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from "vant";
import appconst from "../../lib/appconst";
import store from "../../store";
import ajax from "../../lib/ajax";
const OperatorId = "100002";
// var listss = [];
// let  = "";
// let sum = 0;
export default {
  data() {
    return {
      weixicon: require("../../assets/weixpay.jpg"),
      ybicon: require("../../assets/medical-logo.png"),
      radio: "1",
      price: 0, //临时存储项目费用的
      name: "", //临时存储项目名字的
      btnisClick: true, //是否可点击
      output: "", //处方
      orderId: "",
      listData: {}, //单号信息
      isMore: {
        color: "",
        text: "", //号源多少提示
      },
      rmngColor: "", //号源颜色
      groupList: [], //开单列表数据
      errmgg: "",
      PatientName: "", //开单姓名
      // CardNumber: "",
      PayStatus: "",
      patientid: "",
      Idcard: "",
      FeeSum: 0,
      card: "",
      disabled: false,
      lists: [],
      payArg: {},
      loading: true,
      finished: false,
      wxPayInfo: {
        timeStamp: "",
        nonceStr: "",
        package: "",
        signType: "",
        paySign: "",
      },
      wxconfig: {
        debug: false,
        appId: "",
        timestamp: "",
        nonceStr: "",
        signature: "",
        jsApiList: ["chooseWXPay", "hideOptionMenu"],
      },
      isshow: false,
      // regNo:'',
    };
  },
  created() {
    this.PatientName = store.getters["store/userName"];
    // this.CardNumber = store.getters["store/cardNo"]; //声明就诊卡号
  //     this.regNo = this.$route.query.regNo;
  // console.log('created获取regNo',this.regNo);
    this.patientid = store.getters["store/patientid"]; //声明病例号
    this.onLoad();
  },
  mounted() {
    this.getData(); //获取数据
  },
  methods: {
    gotoPay() {
      // 查询是否已经缴费正在处理状态
      // ajax
      //   .get(`Api/WechatAgentService/AgentExist?regno=${this.regNo}`)
      //   .then((res) => {
      //     console.log(res);
      //     if (res.data.result == false) {
      // 正式模式下
      // http://wx.fgxrmyy.cn/Mpay?regNo=${this.regNo}`}
      ajax
        .get(
          `/Api/WxYBPayServices/GetWechatAuthorizationUrl?retUrl=http://wx.fgxrmyy.cn/YBcors?regNo=${this.regNo}`
        )
        .then((res) => {
          if (res.data.result) {
            window.location.href = res.data.result;
          } else {
            Toast("获取医保授权连接失败");
          }
          console.log(res);
        })
        .catch(() => {
          Toast("获取医保授权连接失败");
        });
      // 测试、开发模式下
      this.$router
        .replace({
          path: `/YBcors?regNo=${this.regNo}&authCode=364151584267681313&retCode=0`,
        })
        .catch((err) => {
          console.log(err);
        });
      // 结束
      //   } else {
      //     Dialog.alert({
      //       title: "提示",
      //       message: "该订单已缴正在处理中,请5分钟后再试！",
      //     }).then(() => {
      //       this.$router.go(-1);
      //       return;
      //       // on close
      //     });
      //   }
      // });
    },
    verify() {
      if (!this.PatientName) {
        Toast.fail({
          message: "用户名不能为空，请重新选取就诊角色",
          duration: 1000,
        });
        return false;
      }
      // if (!this.CardNumber) {
      //   Toast.fail({
      //     message: "就诊卡号不能为空，请重新选取就诊角色",
      //     duration: 1000,
      //   });
      //   return false;
      // }
      if (!this.patientid) {
        Toast.fail({
          message: "病历号不能为空，请重新选取就诊角色",
          duration: 1000,
        });
        return false;
      }
      return true;
    },
    getData() {
      // 号源信息
      ajax.get("/api/services/app/Test/Other_Extend_9008").then((res) => {
        console.log('号源信息', res);
        let listData = res.data.result.list[0];
        this.listData = listData;
        let scheduleDate = listData.scheduleDate;
        this.listData.scheduleDate = scheduleDate.slice(0, 10);
        let rmngMore = 11;
        if (rmngMore > 10) {
          this.isMore.color = "green";
          this.isMore.text = "号源充足";
          this.rmngColor = "blue";
        } else if (rmngMore < 10 && rmngMore > 0) {
          this.isMore.color = "orange";
          this.isMore.text = "号源稀少";
          this.rmngColor = "orange";
        } else {
          this.isMore.color = "red";
          this.isMore.text = "无号源";
          this.rmngColor = "red";
        }
        // console.log(this.listData);
      });
      ajax.get("/api/services/app/Test/Other_Extend_9009").then((res) => {
        console.log("Extend_9009,res");
        console.log(res);
        let groupList = res.data.result.list;
        // console.log("sdsdsddsdsdsdsds")
        // console.log(res.data);
        if (groupList.length == 0) {
          this.isshow = true;
        }
        groupList.forEach((el) => {
          this.groupList.push(el);
        });
        this.errmgg = res.data.result.errorMsg;
        console.log(this.groupList);
        this.loading = false;
        this.finished = true;
        this.btnisClick = false;
        // console.log('aaa'+this.patientid);
        // console.log("Extend_9009");
        // console.log(res.data.result.list[0]);
      });
    },
    // 是否在微信端
    isWeixin() {
      return /MicroMessenger/gi.test(navigator.userAgent);
    },
    // 获取js-SDK
    initWxConfig() {
      // 初始化微信JS功能配置
      return new Promise((resolve, reject) => {
        this.getWxjsSign(appconst.wechatAppId)
          .then((x) => {
            // alert(appconst.wechatAppId);
            var config = {
              appId: appconst.wechatAppId,
              timestamp: x["timestamp"],
              nonceStr: x["noncestr"],
              signature: x["sign"],
              ticket: x["ticket"],
            };
            this.wxconfig = Object.assign(this.wxconfig, config);
            wx.ready((x) => {
              if (x) {
                //
              }
              resolve(this.wxconfig);
            });
            wx.error((err) => {
              if (err) {
                //
              }
              reject(x);
            });
            wx.config(this.wxconfig);
            // alert(wx);
          })
          .catch((x) => {
            reject(x);
          });
      });
    },
    // 获取微信JS签名
    getWxjsSign() {
      let url = encodeURIComponent(location.href);
      var center = `${appconst.thirdApiCenterBaseUrl}Api/WxMpOauth/JsSign`;
      return new Promise((resolve, reject) => {
        ajax
          .get(center, {
            params: {
              appid: appconst.wechatAppId,
              url: url,
            },
          })
          .then((x) => {
            resolve(x.data.result);
            // console.log("测试" + x.data.result);
          })
          .catch((x) => {
            reject(x);
            // console.log(x);
          });
      });
    },
    async onLoad() {
      let me = this;
      if (me.isWeixin()) {
        me.initWxConfig();
        wx.hideOptionMenu(); // 禁用分享

        // TODO：加载订单信息用于显示
      } else {
        Toast.fail({ message: "请在微信中打开此页面", duration: 0 });
      }
      // 异步更新数据
      // setTimeout(() => {
      //   for (let i = 0; i < 0; i++) {
      //     this.lists.push(this.lists.length);
      //   }
      //   // 加载状态结束
      //   this.loading = false;

      //   // 数据全部加载完成
      //   if (this.lists.length == this.lists.length) {
      //     this.finished = true;
      //   }
      // }, 500);

      // 微信获取病人缴费单据
      // let idcard = store.getters["store/idcard"];
      let outpatientId = store.getters["store/patientid"];
      this.patientid = outpatientId;
    },

    // 点击开单按钮
    onclickBtn(p, n, id) {
      if (this.verify()) {
        (this.price = p),
          (this.name = n),
          Dialog.confirm({
            title: "温馨提示",
            message: "确定开单吗？",
          })
            .then(async () => {
              ajax
                .get(
                  `/api/services/app/Test/Other_Extend_9010?userid=${this.patientid}&groupid=${id}`
                )
                .then((res) => {
                  this.output = res.data.result;
                  console.log("this.output");
                  console.log(this.output);
                  this.onclickPay();
                });
            })
            .catch(() => {
              Toast("您已取消操作!");
            });
      }
    },
    // 支付功能
    async onclickPay() {
      window.event.stopPropagation();
      this.disabled = true;
      Toast.loading({
        message: "正在处理中，请稍后...",
        forbidClick: true,
        loadingType: "spinner",
      });
      let me = this;
      // 将要进行支付前 拟定订单
      let openId = store.getters["wechat/openid"];
      let idCard = store.getters["store/idcard"];
      let phone = store.getters["store/phone"];
      var userId = store.getters["store/userId"];
      let listData = this.listData;
      // console.log(listData);
      let ccc = {
        userID: userId,
        userName: this.PatientName,
        idCard,
        phone,
        title: "门诊缴费",
        content: `${listData.deptName}|${this.name}|${this.price}`,
        description: `微信公众号支付|${this.PatientName}|${this.price}`,
        cost: this.price,
        machineName: "wechat",
        wxOpenID: openId,
        registerNo: this.output.registerNo,
      };
      console.log("ccc");
      console.log(ccc);
      if (this.output.registerNo == "") {
        return;
      } else {
        ajax
          .post("/Api/PayService/NewOrder", ccc)
          .then(async (res) => {
            // console.log(res);
            this.orderId = res.data.result;
            // alert(orderid);
            if (this.orderId > 0) {
              await me.getWxPayInfo();
              let aaa = {
                orderId: this.orderId,
                list: [
                  {
                    repNoList: this.output.outRecipeNo,
                    fee: this.price,
                  },
                ],
                patientID: this.patientid,
                registerNo: this.output.registerNo,
                cardTypeID: 4,
                payType: {
                  payTypeID: 3,
                  payModeName: "微信",
                  amount: this.pric,
                },
                operatorId: OperatorId,
                payWay: 3,
                machineName: "wechat",
              };
              // console.log(aaa);
              await ajax
                .post("/Api/WechatAgentService/AgentFeePaid", aaa)
                .then(async (res) => {
                  console.log(res);
                  if (res.data.result === 0 || res.data.result === 2) {
                    console.log("时间戳" + this.wxPayInfo.timeStamp);
                    Toast.clear();
                    wx.chooseWXPay({
                      // appId: appconsts.wechatAppId,
                      timestamp: this.wxPayInfo.timeStamp,
                      nonceStr: this.wxPayInfo.nonceStr,
                      package: this.wxPayInfo.package,
                      signType: this.wxPayInfo.signType,
                      paySign: this.wxPayInfo.paySign,
                      success: async function(res) {
                        let resop = res.errMsg;
                        Toast(resop);
                        Toast(this.orderid);
                        if (resop === "chooseWXPay:ok") {
                          Toast("缴费成功，请注意查看缴费情况");
                          window.location.href = "/CWait";
                        }
                        //     //       //window.location.href = "/WechatPay/Result?returnurl="+encodeURIComponent("@ViewData["returnurl"]")+"&oid=" + '@ViewData["oid"]' + "&state=" + /\w+$/.exec(res.errMsg)[0];
                      },
                      fail: async function(res) {
                        // Toast.clear();
                        if (res) {
                          this.disabled = false;
                          Toast("您已取消缴费");
                        }
                        //     //       console.log("fail");
                      },
                      cancel: async function(res) {
                        // Toast.clear();
                        if (res) {
                          this.disabled = false;
                          Toast("您已取消缴费");
                        }
                        //     //       console.log("cancel");
                      },
                    });
                  } else if (res.data.result === 1) {
                    Toast.clear();
                    Toast("您已缴费成功，请及时查看缴费情况");
                  } else {
                    Toast.clear();
                    Toast("缴费失败，系统异常" + res.data.result);
                  }
                })
                .catch(() => {
                  Toast.clear();
                  // Toast("出现未知错误：" + err);
                });
            } else {
              Toast("创建订单失败");
            }
            //   // console.log(orderid);
          })
          .catch((err) => {
            console.log(err);
            // 更新orderid
          });
      }
    },
    // 创建订单
    async getWxPayInfo() {
      //const query = $route;  // 从Url上获取订单Id
      //me.orderId = query.orderId;
      let orderId = this.orderId;
      // console.log("123456");
      // console.log("orderid是" + orderId);
      await ajax
        .post("/Api/PayService/CreatePay", {
          orderId: orderId,
          payWay: "微信公众号",
          operatorId: OperatorId,
        })
        .then((rep) => {
          let res = rep.data.result;
          // console.log("res是：" + JSON.stringify(res));
          if (res.result === 1) {
            let dat = JSON.parse(res.value);
            // console.log(dat);
            // alert("dat是"+ JSON.stringify(dat));

            this.wxPayInfo.timeStamp = dat.TimeStamp; //时间戳
            this.wxPayInfo.nonceStr = dat.NonceStr; //随机字符串
            this.wxPayInfo.package = dat.Package; //订单详情拓展字符串
            this.wxPayInfo.signType = dat.SignType; //签名方式
            this.wxPayInfo.paySign = dat.PaySign; //签名S
            // console.log("公众号Id：" + this.wxPayInfo.appId);
            // console.log("时间戳" + this.wxPayInfo.timeStamp);
            // console.log("随机字符串" + this.wxPayInfo.nonceStr);
            // console.log("订单详情拓展字符串" + this.wxPayInfo.package);
            // console.log("签名方式" + this.wxPayInfo.signType);
            // console.log("签名" + this.wxPayInfo.paySign);
          } else {
            Dialog.alert({
              message: "签名验证失败",
            }).then(() => {
              //
            });
          }
        })
        .catch((err) => {
          Toast(err);
        });
    },
  },
};
</script>

<style></style>
